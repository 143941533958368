
export default {
  props: ["award"],
  data() {
    return {};
  },
  computed: {
    IsMobile() {
      return this.$q.screen.width < 1023;
    },
  },
  mounted() {
    console.log(this.award);
  },
};
